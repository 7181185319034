import { useState, useEffect } from "react";
import {
  InputGroup,
  Image,
  FormControl,
  Container,
  Row,
  Col,
  Button,
  Alert,
} from "react-bootstrap";
import { Spring, animated } from "@react-spring/web";

import ReCAPTCHA from "react-google-recaptcha";
import DOMPurify from 'dompurify';

import "../App.css";
import "../css/miscCss.css";

//import { SendEmail } from "../helpers/emailSender";
//import { SendRecaptcha } from "../helpers/recaptchaSender";

let part1 = (
  <Spring
    from={{ opacity: 0, bottom: 100, position: "relative" }}
    to={[{ opacity: 1, bottom: 0 }]}
  >
    {(styles) => (
      <animated.div style={styles}>
        <h1 style={{ paddingBottom: 30 }}>
          <b>Get In Touch!</b>
        </h1>
        <p>
          Thank you for your interest in Gina Marie Photography. If you would
          like to book a photo session or if you have any questions, please fill
          out the form below. If I have not responded within 2 hours, please
          email me directly at{" "}
          <a href="mailto:gina@ginamariephotography.ca" className="link">
            <b>gina@ginamariephotography.ca</b>
          </a>
        </p>
        <p>
          I am based in Sarnia, Ontario, but I can travel wherever I'm needed!
        </p>
      </animated.div>
    )}
  </Spring>
);

function Contact() {
  const [captchaVerifyied, setVerifyed] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [messageBody, setBody] = useState("");

  const [showError, setShowError] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const [errorMessage, setErrorMessage] = useState("message");
  const [errorColor, setErrorColor] = useState("danger");
  const [errorHeader, setErrorHeader] = useState("Oh snap! You got an error!");

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) setIsMobile(true);
  }, []);

  const sendEmail = async (value) => {
    if (name !== "" && email !== "" && messageBody !== "") {
      setShowInfo(true);
      setShowError(false);
      //Disables the sent button, so they don't send the email multiple times.
      setVerifyed(false);

      let cleanBody = DOMPurify.sanitize(messageBody);

      let data = { body: cleanBody, name: name, email: email };

      const response = await fetch("http://localhost:8080/mail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "key": "F79XGiGNV4WMsQej45CY"
        },

        body: JSON.stringify(data)
      });

      const json = await response.json();

      setShowInfo(false);
      if (json.sent) {
        setShowSuccess(true);
      } else {
        if (json.error) {
          setErrorColor("danger");
          setErrorHeader("Oh snap! You got an error!");
        } else {
          setErrorColor("warning");
          setErrorHeader("Oops!");
        }

        setShowError(true);
        setErrorMessage(json.msg);
      }


    } else {
      setErrorMessage("Please fill out the required information");
      setErrorColor("warning");
      setErrorHeader("Missing info!");
      setShowError(true);
    }
  };

  const onCaptchaChange = async (value) => {
    if (value != null) {
      setVerifyed(true);
    }

    // setVerifyed(true);
  };

  return (
    <div style={{ marginBottom: 120, zIndex: 1 }}>
      {!isMobile && (
        <div
          style={{
            width: "55%",
            margin: "auto",
            backgroundColor: "white",
            fontFamily: "Open Sans",
            fontSize: 20,
            marginTop: 75,
            marginBottom: 40,
          }}
        >
          {part1}
        </div>
      )}

      {isMobile && (
        <div
          style={{
            width: "80%",
            margin: "auto",
            backgroundColor: "white",
            fontFamily: "Open Sans",
            fontSize: 20,
            marginTop: 75,
            marginBottom: 40,
          }}
        >
          {part1}
        </div>
      )}

      <p>
        Fields marked with a <span style={{ color: "red" }}>*</span> are{" "}
        <u>required</u>
      </p>
      <Container>
        <Row>
          {!isMobile && (
            <Col sm={4}>
              <Image
                src="Gina-Portrait.jpg"
                alt="Gina Marie Photography's founder, Gina Robertson"
                width="100%"
                height="auto"
                style={{ padding: 10, boxShadow: "0px 5px 10px #6C6C6C" }}
                rounded
              />
            </Col>
          )}

          <Col
            sm={8}
            style={{
              fontFamily: "Open Sans",
              textAlign: "left",
            }}
          >
            <p>
              <b>Name</b>
              <span style={{ color: "red" }}>*</span>
            </p>
            <InputGroup>
              <FormControl
                aria-label="Name"
                aria-describedby="basic-addon1"
                onChange={(v) => {
                  setName(v.target.value);
                }}
                value={name}
              />
            </InputGroup>

            <p style={{ paddingTop: 20 }}>
              <b>Email</b>
              <span style={{ color: "red" }}>*</span>
            </p>
            <InputGroup>
              <FormControl
                aria-label="Email"
                aria-describedby="basic-addon1"
                onChange={(v) => {
                  setEmail(v.target.value);
                }}
                value={email}
              />
            </InputGroup>

            <p style={{ paddingTop: 20 }}>
              <b>Message</b>
              <span style={{ color: "red" }}>*</span>
            </p>
            <InputGroup>
              <FormControl
                as="textarea"
                aria-label="With textarea"
                onChange={(v) => {
                  setBody(v.target.value);
                }}
                value={messageBody}
                style={{ height: "180px", maxHeight: "180px" }}
              />
            </InputGroup>
          </Col>
        </Row>
      </Container>
      <div style={{ paddingTop: 50, width: 300, margin: "auto" }}>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
          onChange={onCaptchaChange}
          theme="dark"
        />
      </div>
      <div style={{ paddingTop: 60 }}>
        <Button
          variant="dark"
          size="lg"
          disabled={!captchaVerifyied}
          onClick={() => {
            sendEmail();
          }}
        >
          Send Message
        </Button>
      </div>
      <div className="alertBox">
        <Alert
          style={{ marginBottom: 0 }}
          show={showError}
          variant={errorColor}
          onClose={() => setShowError(false)}
          dismissible
        >
          <Alert.Heading>{errorHeader}</Alert.Heading>
          <p>{errorMessage}</p>
        </Alert>
        <Alert
          style={{ marginBottom: 0 }}
          show={showSuccess}
          variant="success"
          onClose={() => setShowSuccess(false)}
          dismissible
        >
          <Alert.Heading>Your email was sent successfully!</Alert.Heading>
          <p>:)</p>
        </Alert>
        <Alert
          style={{ marginBottom: 0 }}
          show={showInfo}
          variant="secondary"
          onClose={() => setShowInfo(false)}
          dismissible
        >
          <Alert.Heading>Sending email...</Alert.Heading>
        </Alert>
      </div>
    </div>
  );
}

export default Contact;