import React, { useState, useEffect } from "react";
import { Image, Modal } from "react-bootstrap";

import "../App.css";

const ImageViewer = (props) => {
  const [images, setImages] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [imageShow, setImageShow] = useState(false);
  const [imageToShow, setImageToShow] = useState({ name: "", category: "" });

  const [modalStyle, setModalStyle] = useState();

  const [srcName, setSrcName] = useState("");

  const loadImages = async () => {
    try {

      let tempSrcName = props.srcName;

      if(tempSrcName === undefined){
        setSrcName("images");
        tempSrcName = "images";
      }else{
        setSrcName(tempSrcName);
      }

      let response = await fetch(`${tempSrcName}/images.json`);

      let imageInfo = await response.json();

      let typeImages = imageInfo.find(
        (dirInfo) => dirInfo.Key === props.categoryName
      );

      //If a category is given, only set the image array to images in that category.
      //If there is no category given, use all the images and directorys.
      if (typeImages !== undefined) {
        setImages([typeImages]);
      } else {
        setImages(imageInfo);
      }
    } catch (error) {
      setErrorMessage("Woops! There was an issue connected to the server");
    }
  };

  const onImageClick = (event) => {
    let imageNameArray = event.target.src.split("/");

    let imageName = imageNameArray.pop();
    imageNameArray.pop();
    let imageCategory = imageNameArray.pop();
    imageCategory = imageCategory.replace("%20", " ");

    setImageShow(true);
    setImageToShow({ name: imageName, category: imageCategory });
  };

  const loadedImage = () => {
    if (window.innerWidth > window.innerHeight) {
      setModalStyle({
        width: "auto",
        height: "100%",
      });
    } else {
      setModalStyle({
        width: "100%",
        height: "auto",
      });
    }
  };

  useEffect(
    () => {
      loadImages();
    }, // eslint-disable-next-line
    []
  );

  return (
    <div>
      <Modal
        onHide={() => setImageShow(false)}
        show={imageShow}
        fullscreen={true}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <Image
            src={`${srcName}/${imageToShow.category}/${imageToShow.name}`}
            style={{
              ...modalStyle,
              margin: "auto",
              display: "block",
            }}
            onLoad={loadedImage}
          />
        </Modal.Body>
      </Modal>
      <h1>{errorMessage}</h1>
      {images !== null &&
        images.map((dir) => {
          return (
            <div key={dir.Key + "div"}>
              {dir.Value.length > 0 &&
                dir.Value.map((image) => {
                  return (
                    <Image
                      className={"fancyImage"}
                      src={`${srcName}/${dir.Key}/TN/${image}`}
                      key={dir.Key + " " + image}
                      alt={image}
                      onClick={onImageClick}
                    />
                  );
                })}
            </div>
          );
        })}
    </div>
  );
};

export default ImageViewer;
