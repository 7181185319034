import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Spring, animated } from "@react-spring/web";

import ImageSlider from "../components/ImageSlider";
import MobileSlider from "../components/MobileSlider";

import {Link} from "react-router-dom";

import "../App.css";

function Home() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) setIsMobile(true);
  }, []);

  return (
    <div style={{ marginBottom: 125, width: "100%" }}>
      <div
        style={{
          paddingTop: 30,
          paddingBottom: 75,
          width: "60%",
          margin: "auto",
        }}
      >
        <Spring
          from={{ opacity: 0, bottom: 50, position: "relative" }}
          to={[{ opacity: 1, bottom: 0 }]}
        >
          {(styles) => (
            <animated.div style={styles}>
              <h2>Welcome to Gina Marie Photography!</h2>
              <p>
              A full-service, Sarnia-based, professional photographer specializing in families and their milestones such as newborn, children, and family portraiture.
              </p>
              <p>
                <Button variant="outline-dark" as={Link}
                href="#contact"
                to="/contact">Book Now!</Button>
              </p>
            </animated.div>
          )}
        </Spring>
      </div>
      {!isMobile && <ImageSlider />}
      {isMobile && <MobileSlider />}
    </div>
  );
}

export default Home;
