import "../App.css";

const Blurbs = (props) => {
    if (props.keyName === "Maternity & Newborn") {
        return (<div style={{ width: "75%", justifyContent: "center", margin: "auto" }}>

            <h1 style={{ margin: "50px" }}>Maternity and Newborn Portrait</h1>

            <b>MATERNITY SESSIONS</b>
            <p>
                An expectant mother is one of the most beautiful subjects to photograph. Perhaps it's her glow, the excitement in her soul, those beautiful curves, or a combination of all of these factors.
                Gina Marie Photography has a large inventory of maternity gowns, bodysuits, and fabrics for wrapping and tossing to accent any maternity session. Use of these pieces is included in the session.
                Choose from in-studio, outdoors, or a combination of both for your custom session. Maternity sessions are best scheduled between 29-35 weeks of pregnancy, but any time is a good time.
                Our goal is to create visual art that will commemorate and celebrate this incredible milestone. It is the beginning of a beautiful adventure!
                Maternity session pricing starts at $300. This includes a consultation to design your session, an outdoor location, use of one gown, a portable outdoor change room for wardrobe changes, a gallery of digital photos (approximately 15 hand-edited images), and an image-reveal consultation with options to order custom printed product. Session duration approximately 1 hour and can include immediate family members
            </p>

            <b>NEWBORN SESSIONS</b>
            <p>
                Your newborn session is custom designed to your preferences and is uniquely created to celebrate and welcome your precious child into your family. Your session is best done in the first two weeks of your baby's life, while they are still tiny, flexible, and sleepy.
                Gina Marie is a qualified newborn specialist with APNPI and has undergone extensive training and certifications including newborn safety courses.
                An extensive selection of newborn outfits, wraps, props and backdrops are available for the session, so you only need to show up, sit back, and relax during the session.
                Clients are encouraged to pre-book their session at least a month in advance when possible.

            </p>

            <p style={{textAlign: "left"}}>
                <b>Newborn Silver Session</b> pricing starts at $300 and includes a pre-consultation, a 1-hour session (baby only), two different colours of backdrops and outfits. Approximately 15 hand-edited digital images and an option for an image reveal consultation to discuss printed product choices is included in the session fee. No props are included in this session.
            </p>
            <p style={{textAlign: "left"}}>
                <b>Newborn Gold Session</b> pricing starts at $450 and includes a pre-consultation, a 3-hour session (baby and family), various options for colours, outfits, and props. Approximately 30 hand-edited digital images and an option for an image reveal consultation to discuss printed product choices is included in the session fee. A $50.00 print credit is also included.
            </p>
        </div>);
    } else if (props.keyName === "Families") {
        return (<div style={{ width: "75%", justifyContent: "center", margin: "auto" }}>


            <h1 style={{ margin: "50px" }}>Family Portraits</h1>
            <p>
                Hiring a professional photographer to capture and document the essence of your family is an act of love.
                A family portrait session with Gina Marie Photography is more than “just a few pictures”. During our pre-consultation, we will create your session to be in line with what you envision.
                A typical session would include a handful of posed images, while the rest of the session would be candid images of you and your family interacting with one another in your favourite setting.
                This is the best way to capture your personalities and the love that you share with one another. Let's create beautiful art together that you will cherish for years to come.
            </p>

            <p style={{textAlign: "left"}}>
                <b>Immediate Family Session</b> pricing starts at $300 and includes a pre-consultation, an outdoor location of your choice within Lambton County, a 45-minute session, 20 hand-edited digital images, and an optional image reveal/consultation to discuss printed product choices.
                This package is limited to 5 people. Additional $30 per person over 5 people. <b>In-studio</b> sessions are available for an additional $60.
            </p>
            <p style={{textAlign: "left"}}>
                <b>Extended Family Session</b> pricing starts at $450 and includes a pre-consultation, an outdoor location of your choice within Lambton County, a 1.5hr session, 30 hand-edited digital images, and an optional image reveal/consultation to discuss printed product choices.
            </p>

        </div>);
    } else if (props.keyName === "Headshots & Personal Branding") {
        return (<div style={{ width: "75%", justifyContent: "center", margin: "auto" }}>


            <h1 style={{ margin: "50px" }}>Personal Branding</h1>
            <p>
                Everybody needs a great portrait.
                Social media profile photos, dating site profile photos, fashion photography, boudoir sessions, professional business portraits, and business lifestyle sessions are all a part of personal branding.
                No matter what your photographic needs are, together we can create a session unique to you and your style so that your essence is captured.
            </p>

            <p style={{ textAlign: "left" }}>
                <b>Casual Branding Session</b> pricing starts at $275 and includes a pre-consultation, a 1-hour session with time for wardrobe changes, 10 hand-edited digital images of your choice sized for social media. Large digital files are an additional fee. Sessions are typically done in-studio but can be executed outdoors or a combination of both studio/outdoors for an additional fee.
            </p>
            <p style={{ textAlign: "left" }}>
                <b>Business Headshot Session</b> pricing starts at $150 and includes a pre-consultation, a 30-minute session with time for wardrobe changes, 5 hand-edited digital images of your choice sized for social media. Large digital files with transparent backgrounds are an additional fee. Headshot sessions are typically done in-studio but can be executed on-location with portable backdrops.
            </p>
            <p style={{ textAlign: "left" }}>
                <b>Corporate Group Sessions</b> are available. This option is for businesses with more than 10 employee headshots at your place of business. Portable studio is available. Please contact for a quote.
            </p>
            <p style={{ textAlign: "left" }}>
                <b>Business Lifestyle Session</b> pricing starts at $400 and includes a pre-consultation, a 2-hour session in up to 3 different locations, portable change room if needed for outdoor wardrobe changes, 40 lightly edited digital images with an option to purchase more digital images.
            </p>
            <p style={{ textAlign: "left" }}>
                <b>Boudoir Sessions</b> are available. Please contact for quote.
            </p>
        </div>);
    } else if (props.keyName === "Pets") {
        return (<div style={{ width: "75%", justifyContent: "center", margin: "auto" }}>

            <h1 style={{ margin: "50px" }}>Pet Photography</h1>
            <p style={{textAlign: "left"}}>
                <b>Pet Photography Session</b> pricing starts at $300 and is perfectly designed for your furry friend. This approximately 1-hour session includes a pre-consultation, up to two set designs with various options of backdrops, floor-drops to choose from, approximately 10 hand-edited digital images and an option for an image reveal consultation to discuss printed product. Stop Motion Treat Toss is included. Please bring your own treats!
            </p>
        </div>);
    } else if (props.keyName === "Proposals & Weddings") {
        return (<div style={{ width: "75%", justifyContent: "center", margin: "auto" }}>

            <h1 style={{ margin: "50px" }}>Proposals and Weddings</h1>
            <p style={{textAlign: "left"}}>
                <b>Surprise Proposal/Engagement Session</b> You've carefully planned the moment you will ask your life partner those very important four words. “Will you marry me?” Let Gina Marie Photography capture that unforgettable moment for you! This session includes two photographers. We will be inconspicuously ready at the proposal location early so we can capture your proposal from two different angles to ensure we catch all of those amazing facial expressions just like the paparazzi!
                Pricing starts at $250 and includes a pre-consultation, two photographers, and as many lightly edited images as required to ensure you get to relive every moment of the beginning of your lives together.
            </p>
            <p style={{textAlign: "left"}}>
                <b>Wedding Packages</b> Every photo is a visual timestamp; a legacy that will last forever. Gina Marie Photography has several packages to choose from for wedding photography. Included in each wedding package is a pre-consultation, an engagement session, two photographers on your wedding day, and approximately 100+ digital images per hour of shooting time. Please contact to discuss your special day and your photographic needs to see which package works best for you.
            </p>
        </div>);

    } else if (props.keyName === "Children & Motherhood") {

        return (<div style={{ width: "75%", justifyContent: "center", margin: "auto" }}>

            <h1 style={{ margin: "50px" }}>Children and Motherhood Portraits</h1>

            <b>CHILDREN'S SESSIONS</b>
            <p>
                If you're anything like me, you adore watching the children in your life grow from tiny toddlers into children with more questions and opinions than you ever thought possible. Photographing them at any age for any reason is my absolute joy! Like any session with Gina Marie Photography, we can collaborate to create a session that is cohesive with your vision and your style. Childhood sessions include but are not limited to:
            </p>
            <p style={{ textAlign: "left" }}><b>Milestone Mini Session</b> can be at any age. Pricing starts at $250 for a 30 minute session and includes a pre-consultation, one set design with various options of backdrops, floor-drops, and outfits to choose from, approximately 10 hand-edited digital images and an option for an image reveal consultation to discuss printed product.</p>
            <p style={{ textAlign: "left" }}><b>Sitter Session</b> is perfectly designed for babies that are just able to sit but not walking yet. Typically ages 6-12 months but varies from child to child. Pricing starts at $300 for a 45 session and includes a pre-consultation, two set designs with various options of backdrops, floor-drops, and outfits to choose from, approximately 15 hand-edited digital images and an option for an image reveal consultation to discuss printed product.</p>
            <p style={{ textAlign: "left" }}><b>First Year Portrait and Cake Session</b> is perfectly designed for baby's first birthday. Pricing starts at $340 for a 1-hour session and includes a pre-consultation, two set designs with various options of backdrops, floor-drops, and outfits to choose from, approximately 20 hand-edited digital images and an option for an image reveal consultation to discuss printed product. Session also includes the cake and cake stand.</p>

            <b>MOTHERHOOD SESSIONS</b>
            <p>
                These sessions are a celebration of your role as a mother, the unconditional love you have for your children, and the authentic connections you share with them. Together we create emotion-filled images, preserving the most beautiful states of your life. A beautiful assortment of gowns and wrapping/tossing fabrics are available and included in these sessions.
                Pricing starts at $360 for a 1-1.5-hour session and includes a pre-consultation, two set designs with various options of backdrops, floor-drops, props, and outfits to choose from, approximately 20
                hand-edited digital images and an option for an image reveal consultation to discuss printed product.
            </p>
        </div>);

    } 
    else if(props.keyName === "Printed Products"){
     
        return (<div style={{ width: "75%", justifyContent: "center", margin: "auto" }}>

        <h1 style={{ margin: "50px" }}>Printed Products</h1>

        <p>
        Gina Marie Photography offers a diverse range of printed products. 
        All products are made in Canada with high quality papers, materials, and pigment inks. 
        These products are so beautiful, one must see them and touch them to fully appreciate the quality!
        </p>

    </div>);
    }
    else {
        return (<div></div>);
    }
}

export default Blurbs;