import React, { useState } from "react";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";
import { IconContext } from "react-icons";

const imageData = [
  "caro/0.jpg",
  "caro/1.jpg",
  "caro/2.jpg",
  "caro/3.jpg",
  "caro/4.jpg",
  "caro/5.jpg",
  "caro/6.jpg",
  "caro/7.jpg",
  "caro/8.jpg",
  "caro/9.jpg",
  "caro/10.jpg",
  "caro/11.jpg",
  "caro/12.jpg",
  "caro/13.jpg",
  "caro/14.jpg",
  "caro/15.jpg",
];

const ImageSlider = (props) => {
  const [current, setCurrent] = useState(0);
  const length = imageData.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(imageData) || imageData.length <= 0) {
    return null;
  }

  return (
    <section className="slider">
      <IconContext.Provider
        value={{ color: "#343A40", className: "global-class-name" }}
      >
        <FaArrowAltCircleLeft className="left-arrow" onClick={prevSlide} />
        <FaArrowAltCircleRight className="right-arrow" onClick={nextSlide} />
      </IconContext.Provider>
      {imageData.map((slide, index) => {
        return (
          <div
            className={index === current ? "slide active" : "slide"}
            key={index}
          >
            {index === current && <img src={slide} alt="" className="image" />}
          </div>
        );
      })}
    </section>
  );
};

export default ImageSlider;
