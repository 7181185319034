import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "../css/page.min.css";
import "../css/nav.min.css";

// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);

const MobileSlider = () => {
  return (
    <div>
      <Swiper
        style={{ height: "30%" }}
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        className="mySwiper"
      >
        <SwiperSlide>
          <img
            src="caro/1.jpg"
            alt=""
            style={{ width: "95%", borderRadius: "10px" }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="caro/2.jpg"
            alt=""
            style={{ width: "95%", borderRadius: "10px" }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="caro/3.jpg"
            alt=""
            style={{ width: "95%", borderRadius: "10px" }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="caro/4.jpg"
            alt=""
            style={{ width: "95%", borderRadius: "10px" }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="caro/5.jpg"
            alt=""
            style={{ width: "95%", borderRadius: "10px" }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="caro/6.jpg"
            alt=""
            style={{ width: "95%", borderRadius: "10px" }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="caro/7.jpg"
            alt=""
            style={{ width: "95%", borderRadius: "10px" }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="caro/8.jpg"
            alt=""
            style={{ width: "95%", borderRadius: "10px" }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="caro/9.jpg"
            alt=""
            style={{ width: "95%", borderRadius: "10px" }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="caro/10.jpg"
            alt=""
            style={{ width: "95%", borderRadius: "10px" }}
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default MobileSlider;
