import { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "../App.css";

import { Spring, animated } from "@react-spring/web";

let part1 = (
  <div>
    <p>Hello!</p>
    <p>
      Yes, that’s me. Rarely captured in front of the lens. So how did I get
      here?
    </p>
    <p>
      I spent the first 29 years of my career years as a registered nurse. The
      first three years were spent trying a little of everything until I found
      my niche in cardiovascular intensive care.
    </p>
    <p>If there is one BIG lesson that nursing taught me, it is this:</p>
    <p>
      Life is precious. Life is short. Live it; love it; embrace it. If there’s
      something that isn’t quite working, change it. Last but not least,
      DOCUMENT IT! Take more pictures of your loved ones. Take more pictures of
      your adventures, your milestones, or whatever it is that reaches your
      soul. This is your legacy. Pass it on!
    </p>
    <p>
      In staying true to my philosophy, I did reach a time in my life when I
      needed to change things up a bit. So I bought a DSLR camera. My intention
      at the time was to indulge in a new hobby. This hobby soon turned into a
      bit of an obsession, and the next step was automatic. I enrolled in the
      Lambton College Digital Photography Program and graduated on the Dean’s
      List in 2018. I now have traction and momentum with new goals and new
      aspirations! LET’S ROCK THIS!!
    </p>
  </div>
);

let part2 = (
  <div>
    <p>
      I specialize in maternity, newborn, child, and family photography. Gina
      Marie Photography is a <b>QUALIFIED NEWBORN PHOTOGRAPHER with APNPI</b>{" "}
      (The Accredited Professional Newborn Photographers International), and has
      completed the <b>NEWBORN SAFETY COURSES</b> that are required to achieve
      this accreditation.
    </p>
    <p>
      I’m not one to box myself into specific genres, so let’s just say I can do
      it all! Events? YES! Weddings? YES! Boudoir? YES! Pet Portraits with “Stop
      Motion”?? YES YES YES!!
    </p>
    <p>
      My sessions are not about meeting up to take a few quick “shots”. Your
      session is an “experience”. All my sessions include a consultation to
      discuss your vision and help you plan for the best session possible. After
      your session, we will meet again for the image reveal. This is when we
      relax together to view your images on-screen, and I can help you select
      the best options to showcase your beautiful images, whether it be with an
      album, a folio, or wall art.
    </p>
    <p>
      I’d love for you to contact me to discuss your photographic vision. If you
      don’t have one yet, that’s what I’m here for! Let’s brainstorm to create
      something totally unique to you.
    </p>
  </div>
);

function About() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) setIsMobile(true);
  }, []);

  return (
    <div style={{ marginBottom: 120 }}>
      {isMobile && (
        <div
          style={{
            paddingTop: "5%",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <Spring
            from={{ opacity: 0, bottom: 50, position: "relative" }}
            to={[{ opacity: 1, bottom: 0 }]}
          >
            {(styles) => (
              <animated.div style={styles}>
                <p style={{ fontSize: 48 }}>
                  <b>About Me</b>
                </p>
              </animated.div>
            )}
          </Spring>

          <Spring
            from={{ opacity: 0, bottom: 50, position: "relative" }}
            to={[{ opacity: 1, bottom: 0 }]}
          >
            {(styles) => (
              <animated.div style={styles}>
                <Image
                  src="Gina-Portrait.jpg"
                  alt="Gina Marie Photography's founder, Gina Robertson"
                  width="90%"
                  height="auto"
                  style={{ padding: 10, boxShadow: "0px 5px 10px #6C6C6C" }}
                  rounded
                />
              </animated.div>
            )}
          </Spring>

          <div style={{ textAlign: "Left", paddingTop: "30px" }}>
            {part1}
            {part2}
          </div>
        </div>
      )}
      {!isMobile && (
        <div style={{ paddingTop: "2%" }}>
          <Spring
            from={{ opacity: 0, bottom: 50, position: "relative" }}
            to={[{ opacity: 1, bottom: 0 }]}
          >
            {(styles) => (
              <animated.div style={styles}>
                <p style={{ fontSize: 48, paddingBottom: "2%" }}>
                  <b>About Me</b>
                </p>
              </animated.div>
            )}
          </Spring>

          <Container>
            <Row>
              <Col>
                <Spring
                  from={{ opacity: 0, right: 50, position: "relative" }}
                  to={[{ opacity: 1, right: 0 }]}
                >
                  {(styles) => (
                    <animated.div style={styles}>
                      <Image
                        src="Gina-Portrait.jpg"
                        alt="Gina Marie Photography's founder, Gina Robertson"
                        width="100%"
                        height="auto"
                        style={{
                          padding: 10,
                          boxShadow: "0px 5px 10px #6C6C6C",
                        }}
                        rounded
                      />
                    </animated.div>
                  )}
                </Spring>
              </Col>
              <Col>
                <Spring
                  from={{ opacity: 0, left: 50, position: "relative" }}
                  to={[{ opacity: 1, left: 0 }]}
                >
                  {(styles) => (
                    <animated.div style={styles}>
                      <div style={{ textAlign: "Left" }}>{part1}</div>
                    </animated.div>
                  )}
                </Spring>
              </Col>
            </Row>
            <Row>
              <Col>
                <Container
                  style={{
                    textAlign: "left",
                    alignContent: "center",
                    justifyContent: "center",
                    marginTop: 35,
                  }}
                >
                  {part2}
                </Container>
              </Col>
              <Col />
            </Row>
          </Container>
        </div>
      )}
    </div>
  );
}

export default About;
