import "./App.css";

import { Navbar, NavDropdown, Nav, Image } from "react-bootstrap";
import { Route, Link, Redirect, Switch } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Home from "./nav/Home";
import About from "./nav/About";
import Contact from "./nav/Contact";
import Pics from "./nav/Pics";

import Prints from "./nav/Prints";

function App() {
  const [categories, setCategories] = useState([]);

  const navItemStyle = {
    paddingRight: "2.5%",
    paddingLeft: "2.5%",
    fontFamily: "Open Sans",
    fontSize: 20,
    color: "black",
  };

  const linkItemStyle = {
    paddingRight: "1%",
    paddingLeft: "1%",
  };

  const getCategories = async () => {
    try {
      let response = await fetch("images/images.json");
      let imageInfo = await response.json();

      let categoryArray = [];

      imageInfo.forEach((value) => {
        categoryArray.push({
          name: value.Key,
          route: value.Key.replace(/\s/g, "").toLowerCase(),
        });
      });

      setCategories(categoryArray);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="App" style={{ fontFamily: "Open Sans", width: "100%", fontSize: 19 }}>
      <div style={{ backgroundColor: "#FEF3EA", zIndex: 3 }}>
        <Image
          src={"header.png"}
          style={{ paddingTop: 10, width: "100%", height: 135, maxWidth: 213 }}
          alt="Gina Marie Photography logo."
        ></Image>
        <Navbar
          style={{ backgroundColor: "#FEF3EA", justifyContent: "center" }}
          expand="lg"
        >
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              style={{
                width: "100%",
                justifyContent: "center",
                fontFamily: "Open Sans",
              }}
            >
              <Nav.Link as={Link} style={navItemStyle} href="#home" to="/home">
                Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                style={navItemStyle}
                href="#about"
                to="/about"
              >
                About Me
              </Nav.Link>

              <NavDropdown
                style={navItemStyle}
                title="Services"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item
                  as={Link}
                  href={"#prints"}
                  to={"/prints"}
                  key={"printsNav"}
                >
                  Printed Products
                </NavDropdown.Item>
                {categories.length > 0 &&
                  categories.map((value) => {
                    return (
                      <NavDropdown.Item
                        as={Link}
                        href={`#${value.route}`}
                        to={`/${value.route}`}
                        key={value.route + "Nav"}
                      >
                        {value.name}
                      </NavDropdown.Item>
                    );
                  })}
              </NavDropdown>

              <Nav.Link
                as={Link}
                style={navItemStyle}
                href="#contact"
                to="/contact"
              >
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div
          style={{
            paddingBottom: 12,
            boxShadow: "0px 3px 10px #6C6C6C",
            paddingTop: 4,
          }}
        >
          <a
            href="https://www.facebook.com/GinaMariePhotographySarnia"
            target="_blank"
            rel="noreferrer"
            style={linkItemStyle}
          >
            <img src="fb.png" alt="facebook logo" width="28" height="28" />
          </a>
          <a
            href="https://www.instagram.com/ginamariephotographysarnia/"
            target="_blank"
            rel="noreferrer"
            style={linkItemStyle}
          >
            <img src="insta.png" alt="instagram logo" width="28" height="28" />
          </a>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          margin: "auto",
          justifyContent: "center",
        }}
      >
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/home" />} />
          <Route path="/home" render={() => <Home />} />
          <Route path="/about" render={() => <About />} />
          <Route path="/contact" render={() => <Contact />} />
          <Route path="/prints" render={() => <Prints />} />
          {categories.length > 0 &&
            categories.map((value) => {
              return (
                <Route
                  path={`/${value.route}`}
                  render={() => <Pics category={value.name} />}
                  key={value.route}
                />
              );
            })}
          <Route path="*">
            <Redirect to="/home" />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default App;
