import "../App.css";
import ImageViewer from "../components/ImageViewer";
import Blurbs from "../components/Blurbs";



function Pics(props) {
  return (
    <div>

      <Blurbs keyName={props.category}/>
      <div style={{ marginTop: 40, marginBottom: 125, width: "100%" }}>
        <ImageViewer categoryName={props.category} />
      </div>
    </div>
  );
}

export default Pics;
