import "../App.css";
import ImageViewer from "../components/ImageViewer";
import Blurbs from "../components/Blurbs";



function Prints() {

    let textStyle = {
        width: "50%",
        margin:"auto"
    }

    let imageStyle = { 
        marginTop: 10,
         marginBottom: 10, 
         width: "100%" 
    }

    return (
        <div>

            <Blurbs keyName="Printed Products" />

            <div style={Object.assign(textStyle, {marginTop:125})}>
                <p>
                    <b>Wood Mount Wall Cluster<br /></b>
                    Archival Lustre prints with UV laminate,
                    mounted to a wooden frame, and ready to hang in a cluster.
                    Flushmount and Flatmount options are available with a variety of frame/trim colours to choose from.
                </p>
            </div>
            <div style={imageStyle}>
                <ImageViewer categoryName="WallClusters" srcName="prints" />
            </div>

            <div style={textStyle}>
                <p>
                    <b>Canvas with Float Frame<br /></b>
                    Fine art Giclee canvases are laminated with UV protectant to preserve your keepsake for generations to come.
                    Wood float frames measure 2” deep and come in a variety of colours
                </p>
            </div>
            <div style={imageStyle}>
                <ImageViewer categoryName="FloatFrame" srcName="prints" />
            </div>

            <div style={textStyle}>
                <p>
                    <b>Folio Boxes (BEST SELLER/BEST VALUE)<br /></b>
                    Either linen or leatherette keepsake box (11”x14”) that comes with 10, 15, or 20 of your favourite prints, matted and ready to frame.
                    Gift some, hang some, or simply prop some up on a surface using a small stand. A very versatile option for all!
                </p>
            </div>
            <div style={imageStyle}>
                <ImageViewer categoryName="FolioBoxes" srcName="prints" />
            </div>

            <div style={textStyle}>
                <p>
                    <b>Albums<br /></b>
                    Either linen or leatherette (or genuine leather for an added fee) albums come in a variety of sizes.
                    Designed by Gina, the client approves the layout before order is submitted to Canadian printer.
                    As with all products offered, album papers are archival quality.
                    All of these products and more are made available to clients during an optional image reveal/post-consultation.
                </p>
            </div>
            <div style={imageStyle}>
                <ImageViewer categoryName="Albums" srcName="prints" />
            </div>
        </div>
    );
}

export default Prints;